<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/user/userlist')" class="el-icon-close"></i>
      </div>

    <div class="pt20"></div>
        <div class="pstion">
          <div style="display: flex;">账户编号:{{user.user_id}}<div style="margin-left:auto"></div></div>
          <div>{{user.status_text}}</div>
        </div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="账号" :required="true" >
          <el-input v-model="form.account"></el-input>
        </el-form-item>
  
        <el-form-item :required="true" label="密码">
          <el-input placeholder="不改密码请不要输入" v-model="form.password" show-password></el-input>
          <div style="position: absolute;top:0;right:-305px;width:300px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少6位</div>
        </el-form-item>
        
        <el-form-item :required="true" label="确认密码">
          <el-input placeholder="不改密码请不要输入" v-model="form.password_confirmation" show-password></el-input>
          <div style="position: absolute;top:0;right:-305px;width:300px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少6位</div>
        </el-form-item>

        <el-form-item label="手机号">
          <el-input  v-model="form.phone"></el-input>
        </el-form-item>

        <el-form-item label="邮箱">
          <el-input  v-model="form.email"></el-input>
        </el-form-item>
        
        <el-form-item label="姓名">
          <el-input  v-model="form.name"></el-input>
        </el-form-item>
        
        <el-form-item :required="true" label="选择角色">
        <el-select multiple @change="$forceUpdate()"
    collapse-tags v-model="form.role" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      <el-form-item>
        <el-button v-if="!look" type="primary" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          account: '',
          password: '',
          password_confirmation: '',
          phone: '',
          email: '',
          name: '',
          role:[]
        },
        user:{},
        look:false,
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      if(postdata.account == '' ||postdata.password == '' ||postdata.password_confirmation == ''||postdata.role == ''){
        _this.$message.error('请填写必填项');
        return
     }
     if(postdata.password != postdata.password_confirmation){
        _this.$message.error('密码不一致');
        return
      }
      if(postdata.password && postdata.password.length > 0){
          var boo = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,16}$/.test(postdata.password);
          if(!boo){
              _this.$message.error('密码至少包含大写字母，小写字母，数字，且不少于6位!');
              return
          }
      }
     
      axios.post(config.user,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '编辑成功',
                type: 'success'
              });
              _this.goNav('/user/userlist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.user = obj;
        _this.look = obj.look;
        axios.get(config.userInfo,{params:{user_id:obj.user_id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.form.user_id = obj.user_id;
                _this.form.role = [];
                for(let i in _this.form.user_role){
                  _this.form.role.push(_this.form.user_role[i].role_id);
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    initoptions(){
        let _this = this;
        axios.get(config.role,{params:{page:1,status:1,limit:999}})
        .then(function (response) {
            if(response.data.code == 200){
              fiflter(response.data.data.data);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      function fiflter(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].role_id;
          arr.push(obj);
         }
          _this.options = arr;
        }
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
      this.initoptions();
  }
}
</script>
<style scoped>
.pstion{
  position: absolute;
  right: 100px;
  text-align: left;
}
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
